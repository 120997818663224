
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Montserrat:wght@500;700&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
#scaler-app {
  font-family: 'Inter', sans-serif;
  /*
  @media (min-width: $screen-large-min) {
      .scaler-sidebar-left {
          width: 560px;
      }
  }
  */
  /*
  @media (min-width: $screen-large-min) {
      .scaler-blueprint-element,
      .uk-sortable-empty {
          min-height: 50px;
      }
  }
  */
  /*
  @media (min-width: $screen-large-min) {
      .scaler-blueprint-delete-area {
          width: 530px;
      }
  }
  */
  /* Slider active color */
  /* Pointer active animation */ }
  #scaler-app .scaler-navbar-container {
    background: #20242C; }
    #scaler-app .scaler-navbar-container .uk-navbar-item {
      min-height: 60px; }
  #scaler-app .scaler-logo {
    color: #FFF; }
  #scaler-app .scaler-panel-scrollable {
    padding: 0;
    border: 0 none transparent; }
  #scaler-app .scaler-sidebar-left {
    transition: width 0.3s;
    width: 360px;
    background: #454C5A;
    font-size: 12px; }
  #scaler-app .scaler-heading {
    font-family: 'Montserrat', sans-serif; }
  #scaler-app .scaler-icon {
    text-decoration: none; }
  #scaler-app .material-icons.scaler-14 {
    font-size: 14px; }
  #scaler-app .material-icons.scaler-16 {
    font-size: 16px; }
  #scaler-app .material-icons.scaler-18 {
    font-size: 18px; }
  #scaler-app .material-icons.scaler-24 {
    font-size: 24px; }
  #scaler-app .scaler-padding-small {
    padding: 15px; }
  #scaler-app .scaler-iconnav {
    line-height: 1; }
    #scaler-app .scaler-iconnav .uk-active a {
      color: #FFF; }
  #scaler-app .scaler-button-text {
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.035em; }
  #scaler-app .scaler-sidebar-heading {
    background: #303844;
    border-bottom: 2px solid #3FCEC1;
    color: #FFF;
    padding-top: 14px;
    padding-bottom: 14px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.035em; }
  #scaler-app .scaler-button-default {
    background-color: #737985;
    border-color: transparent;
    color: #FFF; }
    #scaler-app .scaler-button-default:hover {
      background: #6c727d; }
    #scaler-app .scaler-button-default:active {
      background: #656a75; }
  #scaler-app .scaler-button-primary {
    background-color: #3FCEC1;
    border-color: transparent;
    color: #FFF; }
    #scaler-app .scaler-button-primary:hover {
      background: #32c2b5; }
    #scaler-app .scaler-button-primary:active {
      background: #2caea2; }
  #scaler-app .scaler-button-secondary {
    color: #20242C; }
  #scaler-app .scaler-blueprint-grid {
    margin-left: -10px; }
    #scaler-app .scaler-blueprint-grid > * {
      padding-left: 10px; }
  #scaler-app .scaler-blueprint-label {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
    font-weight: 600;
    padding-bottom: 10px;
    padding-top: 10px;
    cursor: move; }
  #scaler-app .scaler-blueprint-section-group:hover > div > .scaler-blueprint-menu-button {
    opacity: 1; }
  #scaler-app .scaler-blueprint-section {
    background: #303844;
    padding: 0 10px 10px 10px;
    margin-bottom: 10px; }
  #scaler-app .scaler-blueprint-container {
    background: #737985;
    padding: 5px;
    margin-bottom: 10px; }
  #scaler-app .scaler-blueprint-element {
    background: #BABCC1;
    min-height: 35px;
    color: #20242C;
    font-size: 11px;
    font-weight: 600;
    margin-bottom: 5px;
    cursor: pointer;
    position: relative;
    transition: min-height 0.3s; }
    #scaler-app .scaler-blueprint-element .scaler-blueprint-element-label {
      margin-left: 4px; }
    #scaler-app .scaler-blueprint-element:hover {
      background: #FFF; }
    #scaler-app .scaler-blueprint-element.scaler-blueprint-element-drag {
      background-color: #FFF; }
  #scaler-app .uk-sortable-empty {
    min-height: 35px; }
  #scaler-app .uk-width-1-3 .scaler-blueprint-element-label,
  #scaler-app .uk-width-1-4 .scaler-blueprint-element-label,
  #scaler-app .scaler-blueprint-element-compact .scaler-blueprint-element-label {
    transition: all 0.3s;
    display: none !important; }
  #scaler-app .uk-width-1-3 .scaler-blueprint-add-button,
  #scaler-app .uk-width-1-4 .scaler-blueprint-add-button,
  #scaler-app .scaler-blueprint-element-compact .scaler-blueprint-add-button {
    transition: all 0.3s;
    padding: 3px; }
  #scaler-app .scaler-blueprint-add {
    text-align: center;
    padding: 0; }
  #scaler-app .scaler-blueprint-add-button {
    opacity: 0.5;
    font-size: 11px;
    font-weight: 500;
    text-decoration: none;
    padding: 3px 7px 3px 3px;
    transition: all 0.3s;
    color: #FFF; }
    #scaler-app .scaler-blueprint-add-button .material-icons {
      display: block; }
    #scaler-app .scaler-blueprint-add-button:hover {
      opacity: 1;
      background: #3FCEC1; }
    #scaler-app .scaler-blueprint-add-button:active {
      background: #2caea2; }
  #scaler-app .scaler-blueprint-menu-button {
    margin-right: 6px;
    transition: all 0.3s;
    width: 16px;
    height: 16px;
    display: inline-block;
    text-decoration: none; }
    #scaler-app .scaler-blueprint-menu-button .material-icons {
      display: block; }
    #scaler-app .scaler-blueprint-menu-button:hover {
      background: #3FCEC1; }
    #scaler-app .scaler-blueprint-menu-button:active {
      background: #2caea2; }
  #scaler-app .scaler-blueprint-dropdown {
    min-width: 100px;
    padding: 5px 10px; }
  #scaler-app .scaler-dropdown-nav {
    font-size: 12px; }
    #scaler-app .scaler-dropdown-nav > li > a.uk-flex {
      display: flex;
      padding: 6px 0;
      color: #4b5467; }
      #scaler-app .scaler-dropdown-nav > li > a.uk-flex:hover {
        color: #20242C; }
    #scaler-app .scaler-dropdown-nav .material-icons {
      margin-right: 6px; }
  #scaler-app .scaler-blueprint-delete-area {
    background: #737985;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 330px;
    opacity: 0;
    transition: all 0.3s ease 0.5s;
    transform: translateY(100%);
    padding-top: 15px;
    padding-bottom: 15px; }
  #scaler-app.uk-drag .scaler-blueprint-delete-area {
    opacity: 1;
    transform: translate(0, 0); }
  #scaler-app .scaler-blueprint-delete-drop {
    background-image: linear-gradient(to right, #BABCC1 80%, transparent 20%), linear-gradient(to right, #BABCC1 80%, transparent 20%), linear-gradient(to bottom, #BABCC1 80%, transparent 20%), linear-gradient(to bottom, #BABCC1 80%, transparent 20%);
    background-position: left top, left bottom, left top, right top;
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 12px 2px, 12px 2px, 2px 12px, 2px 12px;
    z-index: 10;
    position: relative; }
    #scaler-app .scaler-blueprint-delete-drop .scaler-blueprint-element {
      margin-bottom: 0; }
  #scaler-app .scaler-blueprint-delete-background {
    padding: 8px;
    font-size: 14px;
    font-weight: 600;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1; }
  #scaler-app .scaler-accordion {
    margin: 0; }
    #scaler-app .scaler-accordion li {
      margin-top: 0; }
      #scaler-app .scaler-accordion li a {
        margin-bottom: 0; }
    #scaler-app .scaler-accordion .uk-accordion-title {
      color: rgba(255, 255, 255, 0.8);
      background: rgba(255, 255, 255, 0.1);
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      transition: background 0.3s;
      padding-top: 12px;
      padding-bottom: 12px;
      font-size: 1.1rem; }
      #scaler-app .scaler-accordion .uk-accordion-title::before {
        background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26' fill='%23DADBDE' width='24px' height='24px'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z'/%3E%3C/svg%3E"); }
    #scaler-app .scaler-accordion > .uk-open > .uk-accordion-title {
      background: transparent;
      border-bottom: 0 none transparent; }
      #scaler-app .scaler-accordion > .uk-open > .uk-accordion-title::before {
        background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26' fill='%23DADBDE' width='24px' height='24px'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z'/%3E%3C/svg%3E"); }
    #scaler-app .scaler-accordion .uk-accordion-content {
      margin-top: 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
  #scaler-app .scaler-grid {
    margin-left: -15px; }
    #scaler-app .scaler-grid > * {
      padding-left: 15px; }
  #scaler-app .scaler-input,
  #scaler-app .scaler-input:focus {
    background-color: #FFF;
    color: #20242C;
    border-width: 2px;
    border-color: #FFF;
    background-clip: border-box;
    font-size: 14px;
    height: 35px;
    transition: all 0.2s; }
  #scaler-app .scaler-select {
    background-color: #FFF;
    color: #20242C;
    border-color: #FFF;
    background-clip: border-box;
    border-width: 2px;
    font-size: 14px;
    height: 35px;
    transition: all 0.2s;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A"); }
  #scaler-app .scaler-textarea {
    background-color: #FFF;
    color: #20242C;
    border-width: 2px;
    border-color: #FFF;
    font-size: 14px;
    transition: all 0.2s; }
  #scaler-app .CodeMirror {
    border-radius: 5px;
    height: 117px; }
  #scaler-app .scaler-input:focus,
  #scaler-app .scaler-select:focus,
  #scaler-app .scaler-textarea:focus,
  #scaler-app .CodeMirror-focused {
    outline: none;
    box-shadow: 0 0 0 2px #3fcec1; }
  #scaler-app .scaler-form-icon {
    background: #BABCC1;
    color: #20242C;
    font-weight: 700;
    border-radius: 5px 0 0 5px;
    font-size: 13px;
    width: 35px; }
    #scaler-app .scaler-form-icon.uk-form-icon-flip {
      border-radius: 0 5px 5px 0; }
    #scaler-app .scaler-form-icon:not(.uk-form-icon-flip) ~ .scaler-input {
      padding-left: 45px !important; }
  #scaler-app .scaler-form-label {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 3px; }
    #scaler-app .scaler-form-label small {
      font-size: 12px;
      font-weight: 400;
      text-transform: none; }
  #scaler-app .scaler-radio-icons label {
    padding: 3px;
    background: transparent;
    border: 1px solid #303844;
    margin-right: 5px;
    height: 24px;
    width: 24px;
    transition: background 0.3s; }
    #scaler-app .scaler-radio-icons label.active {
      background: #303844; }
  #scaler-app .uk-switch {
    position: relative;
    display: inline-block;
    height: 35px;
    width: 50px; }
    #scaler-app .uk-switch input {
      display: none; }
  #scaler-app .uk-switch-slider {
    background-color: #303844;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 500px;
    bottom: 0;
    cursor: pointer;
    transition-property: background-color;
    transition-duration: .2s;
    box-shadow: inset 0 0 2px rgba(32, 36, 44, 0.07);
    margin: 5px 0; }
    #scaler-app .uk-switch-slider:before {
      content: '';
      background-color: #fff;
      position: absolute;
      width: 21px;
      height: 21px;
      left: 2px;
      bottom: 2px;
      border-radius: 50%;
      transition-property: transform, box-shadow;
      transition-duration: .2s; }
  #scaler-app input:checked + .uk-switch-slider {
    background-color: #3FCEC1 !important; }
  #scaler-app input:checked + .uk-switch-slider:before {
    transform: translateX(25px); }
  #scaler-app .scaler-background-dark .uk-switch-slider {
    background-color: #454C5A; }
  #scaler-app .scaler-switch-row {
    height: 55px; }
  #scaler-app .scaler-accordion-secondary {
    margin: 15px 0; }
    #scaler-app .scaler-accordion-secondary .uk-accordion-title {
      color: #FFF;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.035em;
      background: transparent;
      border-bottom: 0 none transparent;
      padding-top: 5px;
      padding-bottom: 5px; }
      #scaler-app .scaler-accordion-secondary .uk-accordion-title::before {
        float: left;
        margin-left: -6px;
        background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23DADBDE' width='18px' height='18px'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z'/%3E%3C/svg%3E"); }
    #scaler-app .scaler-accordion-secondary > .uk-open > .uk-accordion-title {
      background: transparent;
      border-bottom: 0 none transparent; }
      #scaler-app .scaler-accordion-secondary > .uk-open > .uk-accordion-title::before {
        background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23DADBDE' width='18px' height='18px'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z'/%3E%3C/svg%3E"); }
    #scaler-app .scaler-accordion-secondary .uk-accordion-content {
      margin-top: 0;
      border-top: 1px solid rgba(255, 255, 255, 0.2);
      border-bottom: 0 none transparent; }
  #scaler-app .scaler-custom-color-card {
    margin-bottom: 15px; }
    #scaler-app .scaler-custom-color-card:last-child {
      margin-bottom: 0; }
    #scaler-app .scaler-custom-color-card .scaler-color-input {
      max-width: 145px; }
  #scaler-app .scaler-background-dark {
    background: #303844; }
  #scaler-app .scaler-delete-button {
    font-size: 11px;
    font-weight: 500;
    text-decoration: none;
    padding: 3px;
    transition: all 0.3s;
    color: #FFF;
    background: #CE3F3F; }
    #scaler-app .scaler-delete-button .material-icons {
      display: block; }
    #scaler-app .scaler-delete-button:hover {
      background: #c23232; }
    #scaler-app .scaler-delete-button:active {
      background: #ae2c2c; }
  #scaler-app .scaler-font-sizes .uk-flex {
    margin-bottom: 10px; }
    #scaler-app .scaler-font-sizes .uk-flex:last-child {
      margin-bottom: 0; }
  #scaler-app .scaler-font-sizes .scaler-form-label {
    min-width: 75px;
    margin-bottom: 0; }
  #scaler-app .scaler-font-sizes input {
    width: 90px;
    -moz-appearance: textfield; }
    #scaler-app .scaler-font-sizes input::-webkit-outer-spin-button, #scaler-app .scaler-font-sizes input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; }
  #scaler-app .scaler-font-sizes .material-icons {
    opacity: 0.5; }
  #scaler-app .scaler-modal-dialog {
    background: #BABCC1; }
    #scaler-app .scaler-modal-dialog .uk-modal-header {
      background: #303844;
      border-bottom: 2px solid #3FCEC1;
      padding-top: 14px;
      padding-left: 14px;
      padding-bottom: 14px; }
      #scaler-app .scaler-modal-dialog .uk-modal-header .uk-modal-title {
        color: #FFF;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.035em; }
      #scaler-app .scaler-modal-dialog .uk-modal-header .uk-modal-close-default {
        color: #FFF; }
    #scaler-app .scaler-modal-dialog .uk-modal-footer {
      background: #303844; }


